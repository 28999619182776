import axios from '@/config/httpConfig'

// 项目任务，看板视图，实际工作清单详情页请求
export function getKbanViewData(data) {
  return axios.post('/projectTask/viewKanban', data)
}
// 获取项目下里程碑信息
export function getMilestoneMessage(data) {
  return axios.post('/projectMilestone/getMilestoneMessage', data)
}
// 获取任务下的子任务信息
export function getSubtaskMessage(data) {
  return axios.post('/projectTask/getSubtaskMessage', data)
}
// 主页项目列表
export function queryProjectList(data) {
  return axios.post('/project/queryProjectList', data)
}
// 工作量排行
export function queryWorkloadRank(data) {
  return axios.post('/project/queryWorkloadRank', data)
}

// 删除记录
export function del(data) {
  return axios.post('/objectdetail/delete', data)
}

// 添加或更新对象记录
export function save(data) {
  return axios.post('/objectInfo/save', data)
}