<template>
  <!-- 项目任务 -->
  <div class="task_page">
    <!-- 标题栏 -->
    <!-- <div class="title_bar"> -->
    <!-- 左边 -->
    <!-- <div class="left_box"> -->
    <!-- 选择框 -->
    <!-- <div class="select_box">
          <el-select
            v-model="currentSelectChecked"
            placeholder="请选择"
            @change="getKanBanData(currentSelectChecked)"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              style="font-weight: bold"
            >
            </el-option>
          </el-select>
        </div>
        <div class="title_box">
          <span class="arrow">
            <i class="el-icon-arrow-right" />
          </span>
          <span class="whole_text">全部任务</span>
        </div> -->
    <!-- </div> -->

    <!-- 右边功能切换按钮 -->
    <!-- <div class="right_box"> -->
    <!-- 页面切换按钮 -->
    <!-- <div class="jumpToPage">
          <el-button type="primary" size="mini">里程碑</el-button>
          <el-button type="primary" size="mini">甘特图</el-button>
          <el-button type="primary" size="mini">任务看板</el-button>
          <el-button type="primary" size="mini" @click="add"
            >新建里程碑</el-button
          >
        </div>
        <div class="select_box">
          <el-select
            v-model="currentSelectChecked"
            placeholder="请选择"
            @change="getKanBanData(currentSelectChecked)"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              style="font-weight: bold"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div> -->
    <!-- 下面百分比盒子 -->
    <percentage
      ref="change"
      :projectId="projectId"
      @func="childfunc($event)"
    ></percentage>
    <!-- 新增/修改记录 -->
    <create-edit-obj
      ref="createEditObj"
      :prefix="'p02'"
      :objectApi="'cloudccMilestone'"
      :object-name="tabName"
      :realObjId="'add20201207task1'"
      @save="save"
    >
    </create-edit-obj>
  </div>
</template>

<script>
/**
 * 项目任务
 */
import percentage from "./components/percentage"; //百分比组件
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue"; //新建弹框

export default {
  components: {
    percentage,
    createEditObj,
  },
  props: {
    projectId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 列表视图显示隐藏
      // defaultShowLiebiaoshitu: true,
      // isShowKanLiebiaoshitu: false,
      // 看板任务图片显示隐藏
      // defaultShowKanBanImg: true,
      // isShowKanBanImg: false,
      // 筛选器图片显示隐藏
      // defaultShowShaixuanqi: true,
      // isShowKanShaixuanqi: false,
      tabName: this.$i18n.t("label.projectManagement.project.task"), //项目任务
      //批量新增
      // batchAddDialog: false,
      // batchAddDialogTitle: this.$i18n.t("label.object.batchadd", {
      //   objName: this.tabName,
      // }),
      // listObjectApi: "",
      // listPrefix: "",
      //  传给子组件的数据
      // kanbanViewData: [],
      // options: [
      //   {
      //     value: "ffe20201207task11",
      //     label: this.$i18n.t("component.milestones.label.title"), //里程碑
      //   },
      //   {
      //     value: "ffe20201207task09",
      //     label: this.$i18n.t("label.percent"), //百分比
      //   },
      //   {
      //     value: "ffe20201207task10",
      //     label: this.$i18n.t("label.chatter.priority"), //优先级
      //   },
      // ],
      // currentSelectChecked: "ffe20201207task09",
    };
  },
  created() {},
  watch: {},

  methods: {
    // 切换下拉框请求
    // getKanBanData(val) {
      // this.getKanBanView(val);
      // var a = val; //声明个变量存储下数据
      // localStorage.setItem("key", a); //将变量imgs存储到name字段
    // },
     //看板视图任务请求,父组件调用
    getKanBanView(valid, id, parameter) {
      // 
      if (valid === "ffe20201207task09" || valid === "ffe20201207task10") {
        // 
        this.$refs.change.getKanBanViewData(valid, id, parameter);
      } else if (valid === "ffe20201207task11") {
        this.$refs.change.getMilestoneMessage(valid, this.projectId);
      } else if (valid === "kanbanTaskPage") {
        this.$refs.change.getMilestoneMessage(id, parameter);
      } else if (valid === "lichengbei") {
        //
        this.$refs.change.getMilestoneMessage(id);
      }
    },

    // 下拉框赋值
    selectedValue(val) {
      this.currentSelectChecked = val;
    },
    // 新建里程碑按钮
    add() {
      this.$refs.createEditObj.add();
    },

    save() {},
    // 子组件传给父组件的值
    childfunc(val) {
      this.$emit("childfunc", val);
    },
  },
  mounted() {
    this.$refs.change.getMilestoneMessage(this.projectId);
  },
};
</script>

<style lang="scss" scoped>
.task_page {
  width: 99%;
  // 不要在公共组件里设置死高度
  // height: calc(100% - 62px);
  height: 100%;
  padding: 0px 10px 0 10px;
  border: 1px solid #c8c6c6;
  background: #ffffff;
  margin: 0 auto;
  .title_bar {
    display: flex;
    width: 100%;
    height: 52px;

    .left_box {
      width: 50%;
      display: flex;
      border: 1px solid blue;
      .select_box {
        width: 93px;
        padding-top: 5px;
        ::v-deep .el-input--suffix .el-input__inner {
          border: 0px;
        }
        ::v-deep .el-select .el-input .el-select__caret::before {
          content: "";
          width: 0;
          height: 0;
          border: 8px solid #888888;
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
          position: relative;
          top: -12px;
        }
        ::v-deep .el-select .el-input__inner {
          font-size: 14px;
          color: #2c3e50;
        }
      }
      .title_box {
        padding-top: 15px;
        .arrow {
          padding-right: 6px;
        }
      }
    }
    .right_box {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      border: 1px solid red;
      .jumpToPage {
        padding: 10px 10px 0 0px;
      }
      .select_box {
        width: 93px;
        padding-top: 9px;
        ::v-deep .el-input--suffix .el-input__inner {
          border: 0px;
        }
        ::v-deep .el-select .el-input .el-select__caret::before {
          content: "";
          width: 0;
          height: 0;
          border: 8px solid #888888;
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
          position: relative;
          top: -12px;
        }
        ::v-deep .el-select .el-input__inner {
          font-size: 14px;
          color: #2c3e50;
          height: 30px;
          background: #afeeee;
        }
        ::v-deep .el-input__icon {
          line-height: 32px;
        }
      }
    }
  }
  .percentage_task_box {
    width: 100%;
    height: 100%;
  }
}
</style>
