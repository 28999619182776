/* eslint-disable no-irregular-whitespace */
<template>
  <!-- 看板下，里程碑百分比优先级组件 -->
  <div class="percentage_task_box" v-loading="loadings">
    <!-- <div class="left_fixed_box">1</div> -->
    <div
      class="page_is_empty_box"
      v-if="percentageListBox == ''"
      v-loading="loadings"
    >
      <div class="page_is_emptys">
        <svg class="pro-noDataAvailable" aria-hidden="true">
          <use href="#icon-email4"></use>
        </svg>
        <!-- 暂无数据 -->
        <div class="noData">{{ $t("vue_label_SMS_nodata") }}</div>
      </div>
    </div>
      <div
        class="percentage_list_box"
        v-for="(item, index) in percentageListBox"
        v-show="percentageListBox !== ''"
        :key="index"
      >
        <!-- 每个百分比div -->
        <div class="loop_box" v-if="percentageListBox !== ''">
          <div class="bai_fen_bi" v-show="isShowBaifenbi">
            <span>{{ item.grouplabel }}</span>
          </div>
          <div class="you_xian_ji" v-show="isShowYouxianji">
            <div class="bai_you">
              <!-- 无 -->
              <span
                :class="{
                  radius_wu: item.grouplabel === $t('label.tabpage.nothingz'),
                }"
                v-if="item.grouplabel === $t('label.tabpage.nothingz')"
              ></span>
              <!-- 低 -->
              <span
                :class="{
                  radius_di: item.grouplabel === $t('label.low'),
                }"
                v-if="item.grouplabel === $t('label.low')"
              ></span>
              <!-- 中 -->
              <span
                :class="{
                  radius_zhong: item.grouplabel === $t('label.middle'),
                }"
                v-if="item.grouplabel === $t('label.middle')"
              ></span>
              <!-- 高 -->
              <span
                :class="{ radius_gao: item.grouplabel === $t('label.height') }"
                v-if="item.grouplabel === $t('label.height')"
              ></span>
              <span class="Grade">{{ item.grouplabel }}</span>
            </div>
          </div>
          <div class="li_cheng_bei" v-show="isShowLichengbei">
            <div class="left_title">
              <div class="li_cheng_bei_table">
                <div class="milestone_name">
                  <p>{{ item.name }}</p>
                </div>
                <div class="number_of_tasks">
                  ({{ item.completeTaskNum }}/{{ item.taskNum }})
                </div>
              </div>
              <div class="starting_and_ending_time">
                <!-- 起止时间 -->
                {{ $t("label.projectManagement.startingEndingTime") }}:{{
                  item.startDate
                }}/{{ item.endDate }}
              </div>
            </div>
            <div class="right_newly_build">
              <!-- <i class="el-icon-more" @click="newTaskService(item)"></i> -->
              <el-dropdown placement="bottom-end">
                <span class="el-dropdown-link">
                  <svg class="pro-operation1" aria-hidden="true">
                    <use href="#icon-operation1"></use>
                  </svg>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(mewItem, index) in editBulletBoxData"
                    :key="index"
                    @click.native="newTaskService(item, mewItem)"
                  >
                    {{ mewItem.labels }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <!-- 有任务时显示的页面 -->
          <div class="draggable_box">
            <!-- <transition-group tag="div" class="task_list_box"> -->
            <div class="task_list_box">
              <!-- {{item.dataList}} -->
              <!-- 每个百分比内的数据集合 -->
              <draggable
                v-model="dataListArr"
                :options="dragOptions"
                style="height: 100%"
                group="tasks"
                @remove="remove($event, item)"
                :id="item.dataList ? item.grouplabel : item.id"
              >
                <!-- <div class="no_task_box" v-if="item.length == 0 "> -->
                <div
                  class="no_task_box"
                  v-if="
                    item.childTask
                      ? item.childTask.length == 0
                      : item.length == 0
                  "
                >
                  <div class="img_text">
                    <svg class="pro-taskImg" aria-hidden="true">
                      <use href="#icon-taskImg"></use>
                    </svg>
                    <!-- <div> -->
                    <!-- 将一个现有的任务拖放到这个类别 -->
                    <span>{{
                      $t("label.projectManagement.drag.and.drop.existing.tasks")
                    }}</span>
                    <!-- </div> -->
                  </div>
                </div>

                <!-- 里程碑  
                v-show="lichengbeiListData"
              -->
                <div
                  class="card_box"
                  v-show="false"
                  v-for="(item0, index1) in item.childService"
                  :key="index1"
                  :id="item0.serviceId"
                  :align="item0.objectApi"
                >
                  <el-card
                    class="box-card"
                    shadow="hover"
                    v-show="item.childService"
                  >
                    <!-- 表头标题 -->
                    <div class="title_box">
                      <!-- // 里程碑下面的状态设置 -->
                      <div class="title_state">
                        <!-- span这行没用到有时间删除 -->
                        <span class="title_name"></span>
                        <el-tag
                          :type="
                            item0.fulfillPercent == '100%'
                              ? 'danger'
                              : item0.fulfillPercent === '0%'
                              ? 'info'
                              : item0.fulfillPercent !== '0%'
                              ? 'dark'
                              : item0.fulfillPercent !== '100%'
                              ? 'dark'
                              : null
                          "
                          size="mini"
                          effect="dark"
                          >{{ item0.status }}</el-tag
                        >
                      </div>
                      <div
                        class="task_name"
                        :class="{
                          strikethrough: item0.fulfillPercent == '100%',
                        }"
                        @click="projectDetailsPage(item0)"
                      >
                        <!-- ssssssss -->
                      </div>
                    </div>
                    <el-divider></el-divider>
                    <!-- 内容 -->
                    <div class="task_content_box">
                      <div class="img_content">
                        <svg class="img" aria-hidden="true">
                          <use href="#icon-fuwuyuyue"></use>
                        </svg>
                        <div>
                          <span
                            @click="changeServiceName(item0.serviceId)"
                            style="color: #006dcc"
                            >{{ item0.serviceName }}</span
                          >
                        </div>
                      </div>
                      <div class="project_name">
                        <span>{{ item.projectName }}</span>
                      </div>
                    </div>
                    <el-divider></el-divider>
                    <!-- 底部时间。头像 -->
                    <div class="bottom_time_portrait">
                      <!-- 左侧按钮 -->
                      <div class="leftBtn">
                        <!-- 编辑派工单状态下拉框/里程碑百分比-->
                        <div v-if="true">
                          <!-- 派工单状态 -->
                          <el-dropdown
                            @command="handleCommand"
                            size="mini"
                            v-if="true"
                            class="edit"
                          >
                            <span class="el-dropdown-link">
                              {{
                                percentageListBox[index].childService[index1]
                                  .status
                              }}
                              <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                v-for="(status, index) in statusList"
                                :key="index"
                                :command="
                                  beforeHandleCommand(index, index1, status)
                                "
                                >{{ status }}</el-dropdown-item
                              >
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </div>

                      <div class="time_portrait" style="padding-right: 11px">
                        <!-- 结束时间 -->
                        <div class="person_in_charge_box">
                          <div class="charge" v-show="item0.length < 2">
                            <!-- 负责人 -->
                            {{ $t("label_person_in_charge") }}:
                          </div>
                          <div class="many_peoples" v-show="item0.length > 1">
                            <div class="charges" v-show="item0.length > 1">
                              <!-- 负责人 -->
                              {{ $t("label_person_in_charge") }}:
                            </div>
                            <el-dropdown
                              size="mini"
                              split-button
                              @click="
                                handleClick(item0.principal.split(';')[0])
                              "
                            >
                              {{ item0.principalccname.split(";")[0] }}
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                  v-for="(milestoneTask, index) in item0.charge"
                                  :key="index"
                                  @click.native="
                                    manyPeopleClick(milestoneTask.ids)
                                  "
                                  >{{ milestoneTask.names }}</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </div>

                          <div
                            class="many_people"
                            v-show="item0.length < 2"
                            @click="handleClick(item0.principal.split(';')[0])"
                            style="width: 157px"
                          >
                            <!-- 暂无负责人 -->
                            {{
                              item0.length == 0
                                ? $t(
                                    "label.projectManagement.no.person.in.charge"
                                  )
                                : item0.principalccname.split(";")[0]
                            }}
                          </div>
                        </div>
                        <span class="end_date">
                          <!-- 结束日期 -->
                          <!-- 暂无结束日期 -->
                          {{ $t("label.chatter.endData") }}:
                          {{
                            item0.endDate == null
                              ? $t("label.projectManagement.no.end.date")
                              : item0.endDate
                          }}</span
                        >
                      </div>
                    </div>
                  </el-card>
                </div>

                <!-- 任务 -->
                <div
                  class="card_box"
                  v-show="lichengbeiListData"
                  v-for="(item0, index2) in item.childTask"
                  :key="index2"
                  :id="item0.taskId"
                  :align="item0.objectApi"
                >
                  <el-card
                    class="box-card"
                    shadow="hover"
                    v-show="item.childTask"
                  >
                    <!-- 表头标题 -->
                    <div class="title_box" style="display: flex">
                      <div class="left_title_state" style="display: flex">
                        <!-- // 里程碑下面的状态设置 -->
                        <div>
                          <div class="title_states">
                            <!-- span这行没用到有时间删除 -->
                            <span class="title_name"></span>
                            <el-tag
                              :type="
                                item0.fulfillPercent == '100%'
                                  ? 'danger'
                                  : item0.fulfillPercent === '0%'
                                  ? 'info'
                                  : item0.fulfillPercent !== '0%'
                                  ? 'dark'
                                  : item0.fulfillPercent !== '100%'
                                  ? 'dark'
                                  : null
                              "
                              size="mini"
                              effect="dark"
                            >
                              <!-- 未开始  已完成  进行中-->
                              {{
                                item0.fulfillPercent === "0%"
                                  ? $t("label.nostart")
                                  : item0.fulfillPercent === "100%"
                                  ? $t("label.htyj.completed")
                                  : $t("label.starting")
                              }}</el-tag
                            >
                          </div>
                          <div
                            class="task_nameS"
                            :class="{
                              strikethrough: item0.fulfillPercent == '100%',
                            }"
                          >
                            <span
                              @click="projectDetailsPage(item0, 'milepostTask')"
                            >
                              {{ item0.taskName }}
                            </span>
                          </div>
                        </div>

                        <div class="right_icon_box">
                          <el-dropdown placement="bottom-start">
                            <i class="el-icon-more"></i>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                v-for="(taskItem, index) in taskBulletBoxData"
                                :key="index"
                                @click.native="
                                  changeEditTask(item0, taskItem, 'Task')
                                "
                              >
                                {{ taskItem.labels }}
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </div>
                    </div>
                    <el-divider></el-divider>
                    <!-- 内容 -->
                    <div class="task_content_box">
                      <div class="img_content">
                        <svg class="img" aria-hidden="true">
                          <use href="#icon-renwu"></use>
                        </svg>
                        <div>
                          <span>{{ item.name }}</span>
                        </div>
                      </div>
                      <div class="project_name">
                        <span>{{ item.projectName }}</span>
                      </div>
                    </div>
                    <el-divider></el-divider>
                    <!-- 底部时间。头像 -->
                    <div class="bottom_time_portrait">
                      <!-- 左侧按钮 -->
                      <div class="leftBtn">
                        <div v-if="true">
                          <!-- 任务里程碑百分比 -->
                          <el-dropdown
                            @command="handleCommand"
                            size="mini"
                            v-if="true"
                            class="edit"
                          >
                            <span class="el-dropdown-link">
                              {{
                                percentageListBox[index].childTask[index2]
                                  .fulfillPercent
                              }}
                              <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                v-for="(percentage, index) in percentageList"
                                :key="index"
                                :command="
                                  beforeHandleCommand(index, index2, percentage)
                                "
                                >{{ percentage }}</el-dropdown-item
                              >
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>

                        <!-- 子任务按钮 -->
                        <!-- 子任务列表 -->
                        <el-tooltip
                          :content="$t('label.projectManagement.subtask.list')"
                          placement="top"
                          v-if="
                            percentageListBox[index].childTask[index2]
                              .subTaskList.length > 0
                          "
                        >
                          <div
                            class="subtask"
                            @click="toSubtaskList(index, index2)"
                          >
                            <svg class="img" aria-hidden="true">
                              <use href="#icon-subtask"></use>
                            </svg>
                            <div>
                              {{
                                percentageListBox[index].childTask[index2]
                                  .subTaskList.length
                              }}
                            </div>
                          </div>
                        </el-tooltip>
                      </div>
                      <div class="time_portrait">
                        <!-- 结束时间 -->
                        <div class="person_in_charge_box">
                          <div class="charge"></div>
                          <div
                            class="many_peopless"
                            v-show="
                              item0.length > 1 ||
                              item0.principalccname.split(';').length > 1
                            "
                            style="width: 155px; height: 24px"
                          >
                            <div
                              class="charges"
                              v-show="
                                item0.length > 1 ||
                                item0.principalccname.split(';').length > 1
                              "
                            >
                              <!-- 负责人 -->
                              {{ $t("label_person_in_charge") }}:
                            </div>
                            <el-dropdown
                              size="mini"
                              split-button
                              @click="
                                handleClick(item0.principal.split(';')[0])
                              "
                              style="
                                width: 106px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                              "
                              trigger="click"
                            >
                              {{ item0.principalccname.split(";")[0] }}

                              <el-dropdown-menu
                                slot="dropdown"
                                style="
                                  top: 360px;
                                  left: 548px;
                                  width: 100px;
                                  margin-top: 3px;
                                "
                              >
                                <el-dropdown-item
                                  style="
                                    width: 90px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  "
                                  v-for="(milestoneTask, index) in item0.charge"
                                  :key="index"
                                  @click.native="
                                    manyPeopleClick(milestoneTask.ids)
                                  "
                                  >{{ milestoneTask.names }}</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </div>
                          <div
                            class="many_people"
                            v-show="
                              item0.length < 2 ||
                              item0.principalccname.split(';').length < 2
                            "
                            @click="handleClick(item0.principal.split(';')[0])"
                            style="width: 157px"
                          >
                            <!-- 负责人 -->
                            {{ $t("label_person_in_charge") }}:
                            <!-- 暂无负责人 -->
                            {{
                              item0.principalccname == ""
                                ? $t(
                                    "label.projectManagement.no.person.in.charge"
                                  )
                                : item0.principalccname.split(";")[0]
                            }}
                          </div>
                        </div>
                        <span class="end_date1">
                          <!-- 结束日期 -->
                          {{ $t("label.chatter.endData") }}:
                          {{ item0.endDate }}</span
                        >
                      </div>
                    </div>
                  </el-card>
                </div>
                <!-- 任务百分比 -->

                <div
                  v-for="(item0, index0) in item.dataList"
                  :key="index0"
                  :id="item0.taskid"
                  class="card_box"
                  v-show="baifenbiYouxianjiListData"
                >
                  <el-card
                    class="box-card"
                    shadow="hover"
                    v-show="item.length !== 0"
                  >
                    <!-- 表头标题 -->
                    <div class="title_box" style="display: flex">
                      <div class="left_title_state" style="display: flex">
                        <div style="width: 100%">
                          <div
                            class="title_state"
                            v-show="taskStatusDisplayBfenBi"
                          >
                            <span class="title_name">{{ item0.title }}</span>
                            <el-tag
                              :type="
                                item0.fulfillPercent == '100%'
                                  ? 'danger'
                                  : item0.fulfillPercent === '0%'
                                  ? 'info'
                                  : item0.fulfillPercent !== '0%'
                                  ? 'dark'
                                  : item0.fulfillPercent !== '100%'
                                  ? 'dark'
                                  : null
                              "
                              size="mini"
                              effect="dark"
                              >{{ item.taskStatusDisplay }}</el-tag
                            >
                          </div>
                          <div
                            class="title_state"
                            v-show="taskStatusDisplayYXJ"
                          >
                            <span class="title_name">{{ item0.title }}</span>
                            <el-tag
                              :type="
                                item0.fulfillPercent == '100%'
                                  ? 'danger'
                                  : item0.fulfillPercent === '0%'
                                  ? 'info'
                                  : item0.fulfillPercent !== '0%'
                                  ? 'dark'
                                  : item0.fulfillPercent !== '100%'
                                  ? 'dark'
                                  : null
                              "
                              size="mini"
                              effect="dark"
                            >
                              <!-- 未开始  已完成  进行中-->
                              {{
                                item0.fulfillPercent === "0%"
                                  ? $t("label.nostart")
                                  : item0.fulfillPercent === "100%"
                                  ? $t("label.htyj.completed")
                                  : $t("label.starting")
                              }}</el-tag
                            >
                          </div>
                          <div
                            class="task_name"
                            :class="{
                              strikethrough: item0.fulfillPercent == '100%',
                            }"
                          >
                            <span @click="projectDetailsPage(item0)">{{
                              item0.name
                            }}</span>
                          </div>
                        </div>

                        <div class="right_icon_box">
                          <el-dropdown placement="bottom-start">
                            <i class="el-icon-more"></i>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                v-for="(taskItem, index) in taskBulletBoxData"
                                :key="index"
                                @click.native="
                                  changeEditTask(item0, taskItem, 'baifenbi')
                                "
                              >
                                {{ taskItem.labels }}
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </div>
                      <!-- <div class="right_icon_box">
                      <div
                        class="change_icon"
                        @click="changeEditTask(item0, 'baifenbi')"
                      >
                        <i class="el-icon-more"></i>
                      </div>
                    </div> -->
                    </div>
                    <el-divider></el-divider>
                    <!-- 内容 -->
                    <div class="task_content_box">
                      <div class="img_content">
                        <svg class="img" aria-hidden="true">
                          <use href="#icon-lichengbei"></use>
                        </svg>
                        <div>
                          <span>{{ item0.theirMilestoneccname }}</span>
                        </div>
                      </div>
                      <div class="project_name">
                        <span>{{ item0.theirProjectccname }}</span>
                      </div>
                    </div>
                    <el-divider></el-divider>
                    <!-- 底部时间。头像 -->
                    <div class="bottom_time_portrait">
                      <!-- 左侧按钮 -->
                      <div class="leftBtn">
                        <div
                          style="width: 113px"
                          v-show="
                            percentageListBox[index].dataList[index0]
                              .subTaskList.length === 0
                          "
                        >
                          <!-- 子任务为空时展示的div占位使用 -->
                        </div>
                        <!-- 子任务按钮 -->
                        <!-- 子任务列表 -->
                        <el-tooltip
                          :content="$t('label.projectManagement.subtask.list')"
                          placement="top"
                          v-show="
                            percentageListBox[index].dataList[index0]
                              .subTaskList.length > 0
                          "
                        >
                          <div
                            class="subtask"
                            @click="toSubtaskList(index, index0)"
                          >
                            <svg class="img" aria-hidden="true">
                              <use href="#icon-subtask"></use>
                            </svg>
                            <div>
                              {{
                                percentageListBox[index].dataList[index0]
                                  .subTaskList.length
                              }}
                            </div>
                          </div>
                        </el-tooltip>
                      </div>
                      <div class="time_portrait">
                        <!-- 结束时间 -->
                        <div class="person_in_charge_box">
                          <div class="charge" v-show="item0.length < 2">
                            <!-- 负责人 -->
                            {{ $t("label_person_in_charge") }}:
                          </div>
                          <div
                            class="many_people"
                            v-show="item0.length > 1"
                            style="
                              height: 24px;
                              display: flex;
                              width: 155px;
                              padding: 0px;
                            "
                          >
                            <div
                              class="charges"
                              v-show="item0.length > 1"
                              style="padding: 2px 2px 0px 0"
                            >
                              <!-- 负责人 -->
                              {{ $t("label_person_in_charge") }}:
                            </div>
                            <el-dropdown
                              trigger="click"
                              size="mini"
                              split-button
                              @click="handleClick(item0.memberId.split(';')[0])"
                              style="
                                width: 106px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                              "
                            >
                              {{ item0.memberName.split(";")[0] }}
                              <el-dropdown-menu
                                slot="dropdown"
                                style="
                                  top: 360px;
                                  left: 235px;
                                  width: 100px;
                                  margin-top: 3px;
                                  margin-left: 16px;
                                "
                              >
                                <el-dropdown-item
                                  style="
                                    width: 90px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  "
                                  v-for="(people, index) in item0.charge"
                                  :key="index"
                                  @click.native="manyPeopleClick(people.ids)"
                                  >{{ people.names }}</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </div>

                          <!-- 下面判断是不是中文来确定宽度 -->
                          <div
                            class="many_people"
                            v-show="item0.length < 2"
                            @click="handleClick(item0.memberId.split(';')[0])"
                            :style="
                              /[^\u4E00-\u9FA5]/.test(
                                $t('label_person_in_charge')
                              )
                                ? 'width: 86px'
                                : 'width: 106px'
                            "
                          >
                            <!-- 暂无负责人 -->
                            {{
                              item0.length == 0
                                ? $t(
                                    "label.projectManagement.no.person.in.charge"
                                  )
                                : item0.memberName.split(";")[0]
                            }}
                          </div>
                        </div>
                        <span class="end_date">
                          <!-- 结束日期 -->
                          {{ $t("label.chatter.endData") }}:
                          {{ item0.endDate }}</span
                        >
                      </div>
                    </div>
                  </el-card>
                </div>
              </draggable>
            </div>
            <!-- </transition-group> -->
          </div>
        </div>
      </div>
    <!-- 新建任务服务预约 -->
    <!-- 选择 -->
    <el-dialog
      :title="$t('label_tabpage_choose')"
      :visible.sync="typevalueoption"
      width="30%"
      center
    >
      <!-- 选择新建类型 -->
      <span style="margin-left: 80px; margin-right: 5px">{{
        $t("label.projectManagement.select.new.type")
      }}</span>
      <!-- 请选择 -->
      <el-select
        v-model="typevalue"
        :placeholder="$t('label.emailtocloudcc.sel')"
      >
        <el-option
          v-for="item in optionstype"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="typechangecancel">{{
          $t("label.chatter.cancel")
        }}</el-button>
        <!-- 确定 -->
        <el-button type="primary" @click="typechangesave">{{
          $t("label.chatter.ok")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 公共组件新建弹框 -->
    <create-edit-obj
      ref="createEditObjs"
      :prefix="prefix"
      :objectApi="objectApi"
      :object-name="tabName"
      :realObjId="realObjId"
      :relativeRecordId="relativeRecordId"
      :relationFieldId="relationFieldId"
      :id="id"
      :type="type"
      @save="save"
      @update="toUpdate"
    >
    </create-edit-obj>
    <!-- 子任务弹框 -->
    <!-- 子任务列表 -->
    <el-dialog
      :title="$t('label.projectManagement.subtask.list')"
      width="1000px"
      :visible.sync="dialogTableVisible"
    >
      <!-- 新建子任务 -->
      <div style="height: 25px"></div>
      <div class="divBtn">
        <el-button
          type="primary"
          size="mini"
          class="newSubtask"
          @click="addSubtask"
          >{{ $t("label.projectManagement.new.subtask") }}</el-button
        >
      </div>
      <div class="tableData">
        <el-table
          ref="multipleTable"
          :data="SubtaskMessageData"
          style="width: 100%"
          border
        >
          <el-table-column type="index" width="50" align="center">
          </el-table-column>
          <!-- 任务名称 -->
          <el-table-column
            prop="name"
            :label="$t('label.schedulejob.setup.schedule.name')"
            width="140"
            align="center"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-button
                @click="toSubtaskDetails(scope.row)"
                type="text"
                size="small"
                class="taskNameButton"
                >{{ scope.row.name }}</el-button
              >
            </template>
          </el-table-column>
          <!-- 开始时间 -->
          <el-table-column
            prop="startDate"
            :label="$t('label.import.index.starttime')"
            width="150"
            align="center"
          >
          </el-table-column>
          <!-- 结束时间 -->
          <el-table-column
            prop="endDate"
            :label="$t('label.endtime')"
            width="150"
            align="center"
          >
          </el-table-column>
          <!-- 完成百分比 -->
          <el-table-column
            prop="fulfillPercent"
            :label="$t('label.projectManagement.percent.complete')"
            width="120"
            align="center"
          >
          </el-table-column>
          <!-- 负责人 -->
          <el-table-column
            prop="principalccname"
            :label="$t('label_person_in_charge')"
            width="150"
            align="center"
          >
          </el-table-column>
          <!-- 操作  width="140" -->
          <el-table-column
            fixed="right"
            :label="$t('label.import.index.operation')"
            align="center"
            min-width="140x"
          >
            <template slot-scope="scope">
              <el-button
                @click="editDialog(scope.row)"
                type="text"
                size="small"
              >
                <!-- 编辑 -->
                {{ $t("label.emailtocloudcc.edit") }}</el-button
              >
              <el-button @click="delDialog(scope.row)" type="text" size="small">
                <!-- 删除 -->
                {{ $t("label.emailtocloudcc.delete") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 刪除弹框 -->
    <el-dialog
      :title="$t('label.emailtocloudcc.delete')"
      :visible.sync="dialogVisible"
      width="300px"
    >
      <!-- 确认要删除这条数据吗 -->
      <div>
        {{ $t("label.projectManagement.you.want.to.delete.this.data") }}？
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">
          <!-- 取消 -->
          {{ $t("label.emailsync.button.cancel") }}</el-button
        >
        <el-button type="primary" @click="del">
          <!-- 确认 -->
          {{ $t("label.tabpage.ok") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable"; //拖拽
import * as KanBanprojectTasks from "./api"; //看板任务请求
import VueCookies from "vue-cookies";
import * as CommonObjApi from "./api";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue"; //编辑弹框

export default {
  props: {
    projectId: {
      type: String,
      default: "",
    },
  },

  components: {
    draggable,
    createEditObj,
  },
  data() {
    return {
      // 里程碑页面项目为空时显示的页面
      lichengbeiPageEmpty: false,
      percentageList: [
        "0%",
        "10%",
        "20%",
        "30%",
        "40%",
        "50%",
        "60%",
        "70%",
        "80%",
        "90%",
        "100%",
      ],
      statusList: [
        this.$i18n.t("label.tabpage.nothingz"), //无
        this.$i18n.t("label.projectManagement.planned"), //已计划
        this.$i18n.t("label.projectManagement.dispatched"), //已派遣
        this.$i18n.t("label.starting"), //进行中
        this.$i18n.t("label.projectManagement.unable.to.complete"), //无法完成
        this.$i18n.t("label.finished"), //已完成
        this.$i18n.t("label.projectManagement.cancelled"), //已取消
      ],
      indexone: "", //点击的任务里程碑索引
      indextow: "", //点击的任务索引
      // relativeRecordId: "",
      type: "active",
      tabName: "",
      theirTask: "",
      // id: "", //编辑或删除的子任务id
      SubtaskMessageData: [], //子任务列表数据
      dialogTableVisible: false,
      dialogVisible: false,
      editDialogVisible: false,

      ////////////////////
      // 新建任务服务预约弹框显示隐藏
      typevalueoption: false,
      typevalue: "1",
      // 新建数据
      editBulletBoxData: [
        {
          typevalueid: "editLichengbei",
          labels: this.$i18n.t("label.projectManagement.editMilestone"), //编辑里程碑
        },
        //pagecreator.page.button.delete component_milestones_label_title
        {
          typevalueid: "deleteLichengbei",
          labels: this.$i18n.t("c229"), //删除里程碑
        },
        {
          typevalueid: "newTask",
          labels: this.$i18n.t("label.chatter.newtask"), //新建任务
        },
        // {
        //   typevalueid: "newServiceAppointment",
        // labels: this.$i18n.t("label.projectManagement.Newserviceappointment"), //新建服务预约
        // },
      ],
      taskBulletBoxData: [
        {
          typevalueid: "editTask",
          labels: this.$i18n.t("label.task.modify"), //编辑任务
        },
        {
          typevalueid: "deleTask",
          labels: this.$i18n.t("c230"), //删除任务
        },
      ],
      optionstype: [
        {
          value: "1",
          label: this.$i18n.t("label.chatter.task"), //任务
        },
        {
          value: "2",
          label: this.$i18n.t("label.projectManagement.service.appointment"), //服务预约
        },
      ],
      // 公共组件新建弹框需要的参数
      prefix: "",
      objectApi: "",
      relativeRecordId: "",
      relationFieldId: "",
      realObjId: "",
      id: "",
      // 拖拽需要的参数
      getDatapPrameters: "",
      // 拖拽时需要的id参数
      objectApiId: "",
      //
      // viewParameter: "",
      isShowPersonCharge: false,
      // 任务状态显示
      taskStatusDisplayBfenBi: true, //百分比
      taskStatusDisplayYXJ: false, //优先级
      // 百分比页面显示和隐藏
      isShowBaifenbi: true,
      // 优先级页面显示隐藏
      isShowYouxianji: false,
      // 里程碑页面显示
      isShowLichengbei: false,
      // // 有任务时显示的页面
      isShowTaskPage: true,
      // 无任务时候显示的页面
      isShowNoTaskPage: false,
      // 任务状态
      // taskStatusDisplay: "", //未开始
      // 头像
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      //优先级：ffe20201207task10
      //百分比：ffe20201207task09
      viewParameter: "ffe20201207task11",
      percentageListBox: [
        // {
        //   percentageData: "0%",
        //   taskList: [
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //   ],
        // },
        // {
        //   percentageData: "10%",
        //   taskList: [
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //   ],
        // },
        // {
        //   percentageData: "20%",
        //   taskList: [
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //   ],
        // },
        // {
        //   percentageData: "30%",
        //   taskList: [
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //   ],
        // },
        // {
        //   percentageData: "40%",
        //   taskList: [
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //   ],
        // },
        // {
        //   percentageData: "50%",
        //   taskList: [
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //   ],
        // },
        // {
        //   percentageData: "60%",
        //   taskList: [
        //     {
        //       title: "201-t1",
        //       state: "未开始",
        //       taskName: "里程碑里面的第二个任务",
        //       taskList: "列表名称",
        //       project: "2021新项目",
        //       time: "2021-3-3",
        //     },
        //   ],
        // },
      ],
      dataListArr: [],
      loadings: false, // 是否加载
      // 是否显示百分比或者里程碑悬浮卡片
      lichengbeiListData: true,
      baifenbiYouxianjiListData: false,
      // 任务负责人
      milepostTask: "milepostTask",

      // 拖拽需要的参数
      taskData: [], //滤出当前拖拽的这个元素需要的数组
      obj: [], //后台需要保存的参数
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        disabled: false,
        scroll: true,
        group: "sortlist",
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: async function (val) {
        // 
        if (val.path == "/gantt/gantt") {
          localStorage.getItem("key") === "ffe20201207task11"
            ? this.getMilestoneMessage(
                "ffe20201207task11",
                this.$route.query.proId
                  ? this.$route.query.proId
                  : this.projectId
              )
            : this.toUpdate();
          this.$emit("func", localStorage.getItem("key"));
        }
      }, //深度观察监听
      deep: true,
    },
  },
  created() {
    // 看板视图数据请求
    if (
      this.viewParameter === "ffe20201207task09" ||
      this.viewParameter === "ffe20201207task10"
    ) {
      this.getKanBanViewData(this.viewParameter);
    } else if (this.viewParameter === "ffe20201207task11") {
      // let projectId =this.$route.query.proId ? this.$route.query.proId : this.getDatapPrameters;
      // this.getMilestoneMessage(projectId);
    }
    // this.getKanBanViewData(this.viewParameter);
  },
  mounted() {
    //token
    this.tokenCookie = this.$CCDK.CCToken.getToken();
    //域名
    this.imgUrlCookie = VueCookies.get("domainName");
  },
  methods: {
    // 里程碑,百分比，优先级看板编辑任务
    changeEditTask(val, data, parameter) {
      // 
      if (parameter === "Task") {
        //里程碑下的任务编辑删除
        if (data.labels === this.$i18n.t("label.task.modify")) {
          //编辑任务
          this.prefix = "p03";
          this.id = val.taskId;
          this.objectApi = "cloudccTask";
          this.tabName = this.$i18n.t("label.projectManagement.project.task"); //项目任务
          this.$nextTick(() => {
            this.$refs.createEditObjs.edit();
          });
        } else if (
          data.labels ===
          this.$i18n.t("label.emailtocloudcc.delete") +
            this.$i18n.t("label.chatter.task")
        ) {
          //删除任务
          this.$confirm(
            // 删除任务
            this.$i18n.t("label.projectManagement.delete.task"),
            this.$i18n.t("label.tabpage.cue"), //提示
            {
              confirmButtonText: this.$i18n.t("label.tabpage.ok"), //确定
              cancelButtonText: this.$i18n.t("label.emailsync.button.cancel"), //取消
              type: "warning",
            }
          )
            .then(() => {
              this.deleteMilestoneChange(val, data, parameter);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: this.$i18n.t("label.delete.cancel"), //取消删除
              });
            });
        }
      } else if (parameter === "baifenbi") {
        //百分比优先级编辑删除
        if (data.labels === this.$i18n.t("label.task.modify")) {
          //编辑任务
          this.prefix = "p03";
          this.id = val.taskid;
          this.objectApi = "cloudccTask";
          this.tabName = this.$i18n.t("label.projectManagement.project.task"); //项目任务
          this.$nextTick(() => {
            this.$refs.createEditObjs.edit();
          });
        } else if (
          data.labels ===
          this.$i18n.t("label.emailtocloudcc.delete") +
            this.$i18n.t("label.chatter.task")
        ) {
          //删除任务
          this.$confirm(
            // 删除任务
            this.$i18n.t("label.projectManagement.delete.task"),
            this.$i18n.t("label.tabpage.cue"), //提示
            {
              confirmButtonText: this.$i18n.t("label.tabpage.ok"), //确定
              cancelButtonText: this.$i18n.t("label.emailsync.button.cancel"), //取消
              type: "warning",
            }
          )
            .then(() => {
              this.deleteMilestoneChange(val, data, parameter);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: this.$i18n.t("label.delete.cancel"), //取消删除
              });
            });
        }
      }
    },
    // 里程碑新建任务/服务预约
    newTaskService(val, data) {
      // 
      // this.typevalue = "1";
      // this.realObjId = val.id;
      // this.typevalueoption = true;
      if (data.typevalueid === "editLichengbei") {
        this.id = val.id;

        this.objectApi = "cloudccMilestone";
        this.tabName = this.$i18n.t("component.milestones.label.title"); //里程碑
        this.$nextTick(() => {
          this.$refs.createEditObjs.edit();
        });
      } else if (data.typevalueid === "newTask") {
        //任务
        this.prefix = "p03";
        this.id = "";
        this.relationFieldId = "ffe20201207task02";
        this.tabName = this.$i18n.t("label.chatter.task"); //任务
        this.objectApi = "cloudccTask";
        this.relativeRecordId = val.id;
        this.addMissionServices();
      } else if (data.typevalueid === "newServiceAppointment") {
        //服务预约
        this.prefix = "s41";
        this.id = "";
        this.relationFieldId = "ffe21sappointment083";
        this.tabName = this.$i18n.t(
          "label.projectManagement.service.appointment"
        ); //服务预约
        this.objectApi = "ServiceAppointment";
        this.relativeRecordId = val.id;
        this.addMissionServices();
      } else if (data.typevalueid === "deleteLichengbei") {
        this.$confirm(
          // 删除里程碑
          this.$i18n.t("label.projectManagement.delete.milestone"),
          this.$i18n.t("label.tabpage.cue"), //提示
          {
            confirmButtonText: this.$i18n.t("label.tabpage.ok"), //确定
            cancelButtonText: this.$i18n.t("label.emailsync.button.cancel"), //取消
            type: "warning",
          }
        )
          .then(() => {
            let dele = "deleLichengbei";
            this.deleteMilestoneChange(val, dele);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$i18n.t("label.delete.cancel"), //取消删除
            });
          });
      }
    },
    // 删除里程碑 任务
    async deleteMilestoneChange(val, data, parameter) {
      if (data === "deleLichengbei") {
        //删除里程碑
        let dele = {
          id: val.id,
          objectApi: "cloudccMilestone",
        };
        let res = await CommonObjApi.del(dele);
        if (res.result) {
          this.$message.success(this.$i18n.t("label.tabpage.delsuccessz")); //删除成功
          this.getMilestoneMessage(this.getDatapPrameters);
          this.$bus.$emit("refreshListFun"); //刷新
        }
      } else if (parameter === "Task") {
        //里程碑下的任务删除
        let dele = {
          id: val.taskId,
          objectApi: "cloudccTask",
        };
        let res = await CommonObjApi.del(dele);
        if (res.result) {
          this.$message.success(this.$i18n.t("label.tabpage.delsuccessz")); //删除成功
          this.getMilestoneMessage(this.getDatapPrameters);
          this.$bus.$emit("refreshListFun"); //刷新
        }
      } else if (parameter === "baifenbi") {
        //百分比优先级删除
        if (val.part === "youxianji") {
          let dele = {
            id: val.taskid,
            objectApi: "cloudccTask",
          };
          let res = await CommonObjApi.del(dele);
          if (res.result) {
            this.$message.success(this.$i18n.t("label.tabpage.delsuccessz")); //删除成功
            let task10 = "ffe20201207task10";
            this.getKanBanViewData(task10, this.getDatapPrameters);
            this.$bus.$emit("refreshListFun"); //刷新
          }
        } else if (val.part === "baifenbi") {
          let dele = {
            id: val.taskid,
            objectApi: "cloudccTask",
          };
          let res = await CommonObjApi.del(dele);
          if (res.result) {
            this.$message.success(this.$i18n.t("label.tabpage.delsuccessz")); //删除成功
            let task09 = "ffe20201207task09";
            this.getKanBanViewData(task09, this.getDatapPrameters);
            this.$bus.$emit("refreshListFun"); //刷新
          }
        }
      }
    },
    // 取消隐藏新建弹框
    typechangecancel() {
      this.typevalueoption = false;
    },
    // 确定按钮
    typechangesave() {
      // if (this.typevalue === "1") {
      //   //任务
      //   this.prefix = "p03";
      //   this.relationFieldId = "ffe20201207task02";
      //   this.tabName = "任务";
      //   this.objectApi = "cloudccTask";
      //   this.relativeRecordId = this.realObjId;
      //   this.addMissionServices();
      // } else if (this.typevalue === "2") {
      //   //服务预约
      //   this.prefix = "s41";
      //   this.relationFieldId = "ffe21sappointment083";
      //   this.tabName = "服务预约";
      //   this.objectApi = "ServiceAppointment";
      //   this.relativeRecordId = this.realObjId;
      //   this.addMissionServices();
      // }
    },
    // 新建
    addMissionServices() {
      this.$nextTick(() => {
        this.$refs.createEditObjs.add();
      });
    },
    save() {
      this.typevalueoption = false;
      let a = "saveData";
      this.getMilestoneMessage(this.getDatapPrameters, a);
    },
    //新建子任务
    addSubtask() {
      this.prefix = "p05";
      this.objectApi = "cloudccSubtask";
      this.relationFieldId = "ffe20201204subtask02";

      this.tabName = this.$i18n.t("label.projectManagement.subtasks"); //子任务
      this.relativeRecordId = this.percentageListBox[this.indexone].childTask
        ? // localStorage.getItem("key") === "ffe20201207task11"
          this.percentageListBox[this.indexone].childTask[this.indextow].taskId
        : this.percentageListBox[this.indexone].dataList[this.indextow].taskid;

      this.id = "";
      this.theirTask = this.percentageListBox[this.indexone].childTask
        ? // localStorage.getItem("key") === "ffe20201207task11"
          this.percentageListBox[this.indexone].childTask[this.indextow].taskId
        : this.percentageListBox[this.indexone].dataList[this.indextow].taskid;

      setTimeout(() => {
        // this.$refs.createEditObj[0].add();
        this.$refs.createEditObjs.add();
        // 
        this.toUpdate();
      }, 500);
    },
    // 编辑
    editDialog(row) {
      this.prefix = "p05";
      this.objectApi = "cloudccSubtask";
      this.relationFieldId = "ffe20201204subtask02";

      this.tabName = this.$i18n.t("label.projectManagement.subtasks"); //子任务
      this.id = row.id;
      this.theirTask = row.theirTask;
      // this.$refs.createEditObj[0].add();
      setTimeout(() => {
        this.$refs.createEditObjs.edit();
        this.toUpdate();
      }, 500);
    },

    //删除弹框显示
    delDialog(row) {
      this.dialogVisible = true;
      this.theirTask = row.theirTask;
      this.id = row.id;
    },
    //删除子任务
    del() {
      this.dialogVisible = false;
      CommonObjApi.del({
        id: this.id,
        objectApi: "cloudccSubtask",
      }).then((res) => {
        if (res.result) {
          this.$message.success(this.$i18n.t("label.tabpage.delsuccessz")); //删除成功
          this.toUpdate();
        }
      });
    },
    // 保存或修改页面更新
    async toUpdate() {
      let parameter = {
        taskId: this.theirTask,
        projectId: this.$route.query.proId
          ? this.$route.query.proId
          : this.projectId,
      };
      let res = await KanBanprojectTasks.getSubtaskMessage(parameter);
      let res1 =
        localStorage.getItem("key") === "ffe20201207task11"
          ? await KanBanprojectTasks.getMilestoneMessage(parameter)
          : this.getKanBanViewData(
              localStorage.getItem("key") === "ffe20201207task09"
                ? "ffe20201207task09"
                : "ffe20201207task10",
              this.$route.query.proId ? this.$route.query.proId : this.projectId
            );

      this.SubtaskMessageData = res.data;
      this.percentageListBox = res1.data;
    },
    //跳子任务详情
    toSubtaskDetails(row) {
      // 
      this.$router.push({
        path: `/commonObjects/detail/${row.id}/DETAIL`,
        query: { sign: "back" },
      });
    },
    //子任务列表
    toSubtaskList(index, index0) {
      this.indexone = index;
      this.indextow = index0;
      // alert("子任务列表");
      this.dialogTableVisible = true;
      // var taskId;
      // if (this.percentageListBox[index].dataList) {
      //   taskId = this.percentageListBox[index].dataList[index0].taskid;
      // }
      // else if (this.percentageListBox[index].childService.length > 0) {
      //   taskId = this.percentageListBox[index].childService[index0].serviceId;
      // }
      // else {
      //   taskId = this.percentageListBox[index].childTask[index0].taskId;
      // }

      // let parameter = {
      //   taskId: taskId,
      // };
      // let res = await KanBanprojectTasks.getSubtaskMessage(parameter);
      // this.SubtaskMessageData = res.data;
      if (this.percentageListBox[index].dataList) {
        this.SubtaskMessageData =
          this.percentageListBox[index].dataList[index0].subTaskList;
      } else {
        this.SubtaskMessageData =
          this.percentageListBox[index].childTask[index0].subTaskList;
      }
      // 
      // 
      // .data.subtasks
      // taskId = "";
    },
    beforeHandleCommand(index, index0, command) {
      return {
        index,
        index0,
        command,
      };
    },
    //修改百分比、状态
    async handleCommand(command) {
      //任务id
      // 
      // 
      if (this.percentageListBox[command.index].childService[command.index0]) {
        if (command.command.indexOf("%") !== -1) {
          this.percentageListBox[command.index].childTask[
            command.index0
          ].fulfillPercent = command.command;
          let id =
            this.percentageListBox[command.index].childTask[command.index0]
              .taskId;
          let fulfill_percent =
            this.percentageListBox[command.index].childTask[command.index0]
              .fulfillPercent;

          let params = {
            objectApi:
              this.percentageListBox[command.index].childTask[command.index0]
                .objectApi,
            data: JSON.stringify([{ id, fulfill_percent }]),
          };
          await CommonObjApi.save(params);
        } else {
          this.percentageListBox[command.index].childService[
            command.index0
          ].status = command.command;
          let id =
            this.percentageListBox[command.index].childService[command.index0]
              .serviceId;
          let status =
            this.percentageListBox[command.index].childService[command.index0]
              .status;

          let params = {
            objectApi:
              this.percentageListBox[command.index].childService[command.index0]
                .objectApi,
            data: JSON.stringify([{ id, status }]),
          };
          await CommonObjApi.save(params);
        }
      } else {
        this.percentageListBox[command.index].childTask[
          command.index0
        ].fulfillPercent = command.command;

        let id =
          this.percentageListBox[command.index].childTask[command.index0]
            .taskId;
        let fulfill_percent =
          this.percentageListBox[command.index].childTask[command.index0]
            .fulfillPercent;
        let task_status =
          fulfill_percent == "0%"
            ? this.$i18n.t("label.nostart") //未开始
            : fulfill_percent == "100%"
            ? this.$i18n.t("label.htyj.completed") //已完成
            : this.$i18n.t("label.starting"); //进行中

        let params = {
          objectApi:
            this.percentageListBox[command.index].childTask[command.index0]
              .objectApi,
          data: JSON.stringify([{ id, fulfill_percent, task_status }]),
        };
        await CommonObjApi.save(params);
      }
      this.getMilestoneMessage(
        this.$route.query.proId ? this.$route.query.proId : this.projectId
      );
    },
    // 页面展示负责人
    handleClick(val) {
      // 
      this.$router.push({
        path: `/commonObjects/detail/${val}/DETAIL`,
        query: { sign: "back" },
      });
    },
    // 选项负责人
    manyPeopleClick(ids) {
      this.$router.push({
        path: `/commonObjects/detail/${ids}/DETAIL`,
        query: { sign: "back" },
      });
    },
    // 点击跳转项目详情页
    projectDetailsPage(val, data) {
      //  
      if (data === "milepostTask") {
        this.$router.push({
          path: `/commonObjects/detail/${val.taskId}/DETAIL`,
          query: { sign: "back" },
        });
      } else {
        this.$router.push({
          path: `/commonObjects/detail/${val.taskid}/DETAIL`,
          query: { sign: "back" },
        });
      }
    },
    // 看板任务视图视图请求
    async getKanBanViewData(val, id, parameterItem) {
      // 
      if (parameterItem === "login") {
        this.loadings = true;
      }
      // this.loadings = true;
      this.baifenbiYouxianjiListData = true;
      this.lichengbeiListData = false;
      //  百分比
      if (val === "ffe20201207task09") {
        this.isShowBaifenbi = true;
        this.isShowYouxianji = false;
        this.isShowLichengbei = false;
        let parameter = {
          fieldId: val,
          projectId:
            this.$route.query.proId !== undefined
              ? this.$route.query.proId
              : this.projectId,
          objectApi: "cloudccTask",
        };
        let res = await KanBanprojectTasks.getKbanViewData(parameter);
        this.loadings = false;
        this.percentageListBox = res.data.groupData;
        // 
        this.$parent.selectedValue(val); //给父组件的下拉框赋值
        this.percentageListBox.map((item) => {
          item.length = item.dataList.length;
          if (item.grouplabel === "0%") {
            item.taskStatusDisplay = this.$i18n.t("label.nostart"); //未开始
          } else if (item.grouplabel === "100%") {
            item.taskStatusDisplay = this.$i18n.t("label.htyj.completed"); //已完成
          } else {
            item.taskStatusDisplay = this.$i18n.t("label.starting"); //进行中
          }
          // 区分百分比和优先级，点击项目名称进入详情页返回时候用到的参数
          item.dataList.map((bfb) => {
            return (bfb.part = "baifenbi");
          });
          // 判断头像有几个负责人
          if (item.dataList !== "") {
            item.dataList.map((headData) => {
              if (headData.memberId !== "" && headData.memberId !== null) {
                headData.length = headData.memberId.split(";").length;
                if (headData.length > 1) {
                  let fruits = headData.memberName.split(";");
                  let memberId = headData.memberId.split(";");
                  let charge = [];
                  memberId.map((items, indexs) => {
                    charge.push({
                      ids: items,
                      names: fruits[indexs],
                    });
                  });
                  headData.charge = charge.splice(1, charge.length - 1);
                }
              } else {
                headData.length = 0;
              }
            });
          }
          // 
        });
        this.taskStatusDisplayBfenBi = true;
        this.taskStatusDisplayYXJ = false;
        this.viewParameter = localStorage.getItem("key");
      } else if (val === "ffe20201207task10") {
        this.isShowBaifenbi = false;
        this.isShowYouxianji = true;
        this.isShowLichengbei = false;
        //优先级
        let parameter = {
          fieldId: val,
          projectId:
            this.$route.query.proId !== undefined
              ? this.$route.query.proId
              : this.projectId,
          objectApi: "cloudccTask",
        };
        let res = await KanBanprojectTasks.getKbanViewData(parameter);
        this.loadings = false;
        this.percentageListBox = res.data.groupData.reverse();
        this.$parent.selectedValue(val); //给父组件的下拉框赋值
        this.percentageListBox.map((item) => {
          item.length = item.dataList.length;
          // 区分百分比和优先级，点击项目名称进入详情页返回时候用到的参数
          item.dataList.map((bfb) => {
            return (bfb.part = "youxianji");
          });
          // 判断有几个负责人
          if (item.dataList !== "") {
            item.dataList.map((headData) => {
              if (headData.memberId !== "" && headData.memberId !== null) {
                headData.length = headData.memberId.split(";").length;
                if (headData.length > 1) {
                  let fruits = headData.memberName.split(";");
                  let memberId = headData.memberId.split(";");
                  let charge = [];
                  memberId.map((items, indexs) => {
                    charge.push({
                      ids: items,
                      names: fruits[indexs],
                    });
                  });
                  headData.charge = charge.splice(1, charge.length - 1);
                }
              } else {
                headData.length = 0;
              }
            });
          }
        });
        // 
        this.taskStatusDisplayBfenBi = false;
        this.taskStatusDisplayYXJ = true;
        this.viewParameter = localStorage.getItem("key");
      }
    },
    // 获取项目下里程碑信息
    async getMilestoneMessage(ary, valid) {
      // 
      this.getDatapPrameters = ary;
      if (valid === "ffe20201207task11") {
        this.loadings = true;
      }
      // 表头
      this.isShowLichengbei = true;
      this.isShowYouxianji = false;
      this.isShowBaifenbi = false;
      // 列表
      this.baifenbiYouxianjiListData = false;
      this.lichengbeiListData = true;
      let parameter = {
        projectId: this.$route.query.proId
          ? this.$route.query.proId
          : this.projectId,
      };
      let res = await KanBanprojectTasks.getMilestoneMessage(parameter);
      this.loadings = false;
      this.percentageListBox = res.data;
      this.percentageListBox.map((items) => {
        items.childTask.map((itemTask) => {
          if (
            itemTask.principal !== "" &&
            itemTask.principal !== undefined &&
            itemTask.principal !== null
          ) {
            itemTask.length = itemTask.principal.split(";").length;
            if (itemTask.length > 1) {
              let fruits = itemTask.principalccname.split(";");
              let memberId = itemTask.principal.split(";");
              let charge = [];
              memberId.map((items, indexs) => {
                charge.push({
                  ids: items,
                  names: fruits[indexs],
                });
              });
              itemTask.charge = charge.splice(1, charge.length - 1);
            }
          } else {
            itemTask.length = 0;
          }
          // 
        });
        ///////-
        items.childService.map((itemTask) => {
          if (itemTask.principal !== "" && itemTask.principal !== undefined) {
            itemTask.length = itemTask.principal.split(";").length;
            if (itemTask.length > 1) {
              let fruits = itemTask.principalccname.split(";");
              let memberId = itemTask.principal.split(";");
              let charge = [];
              memberId.map((items, indexs) => {
                charge.push({
                  ids: items,
                  names: fruits[indexs],
                });
              });
              itemTask.charge = charge.splice(1, charge.length - 1);
            }
          } else {
            itemTask.length = 0;
          }
        });
      });
      if (
        valid === "saveData" &&
        localStorage.getItem("key") === "ffe20201207task11"
      ) {
        this.loadings = true;
        this.getMilestoneMessage(this.getDatapPrameters);
      }
      // 
    },
    // 开始拖动时触发的事件
    async remove(evt, val, parameter) {
      // 
      this.loadings = false;
      if (val.dataList) {
        val.dataList.filter((itemsId) => {
          if (evt.item.id === itemsId.taskid) {
            return (this.objectApiId = itemsId.objectApi);
          }
        });
        // 无 低 中 高
        if (
          val.grouplabel === this.$i18n.t("label.tabpage.nothingz") ||
          val.grouplabel === this.$i18n.t("label.low") ||
          val.grouplabel === this.$i18n.t("label.middle") ||
          val.grouplabel === this.$i18n.t("label.height")
        ) {
          let ids = evt.item.id; // 获取拖动元素的id
          let obj = [{ id: ids, priority: evt.to.id }];
          let params = {
            objectApi: this.objectApiId,
            data: JSON.stringify(obj),
          };
          await CommonObjApi.save(params);
          let arr = "ffe20201207task10";
          this.getKanBanViewData(arr, this.getDatapPrameters);
        } else {
          let taskStatus = "";
          if (evt.to.id === "0%") {
            taskStatus = this.$i18n.t("label.nostart"); //未开始
          } else if (evt.to.id === "100%") {
            taskStatus = this.$i18n.t("label.htyj.completed"); //已完成
          } else {
            taskStatus = this.$i18n.t("label.starting"); //进行中
          }
          let ids = evt.item.id; // 获取拖动元素的id
          let obj = [
            { id: ids, fulfill_percent: evt.to.id, task_status: taskStatus },
          ];
          let params = {
            objectApi: this.objectApiId,
            data: JSON.stringify(obj),
          };
          await CommonObjApi.save(params);
          let arr = "ffe20201207task09";
          this.getKanBanViewData(arr, this.getDatapPrameters);
          this.$parent.selectedValue(arr);
        }
      } else {
        //里程碑
        // let liChengBeistartTime = ""; //被拖进去里程碑的开始时间
        // let liChengBeiendTime = ""; //被拖进去里程碑的结束时间
        if (val) {
          let lichengbeiData = this.percentageListBox.filter((lichengbeiID) => {
            //过滤出获取到被拖进去的哪一项里程碑
            return lichengbeiID.id == evt.to.id;
          });
          lichengbeiData.map((lichengbeiData) => {
            //循环过滤出的哪一项拿到里程碑的开始时间跟结束时间

            return (
              new Date(lichengbeiData.startDate),
              (new Date(lichengbeiData.endDate))
            );
          });
        }

        // let taskstartTime = ""; //任务的开始时间 /服务预约的开始时间
        // let taskendTime = ""; //任务的结束时间  /服务预约的结束时间
        if (
          evt.item.align === "cloudccTask" ||
          evt.item.align === "ServiceAppointment"
        ) {
          //如果拖拽的是任务就走这一项
          if (evt.item.align === "cloudccTask") {
            this.taskData = val.childTask.filter((itemTaskId) => {
              //过滤出当前拖拽的这个元素
              return itemTaskId.taskId == evt.item.id;
            });
          } else if (evt.item.align === "ServiceAppointment") {
            this.taskData = val.childService.filter((itemTaskId) => {
              //过滤出当前拖拽的这个元素
              return itemTaskId.serviceId == evt.item.id;
            });
          }

          this.taskData.map((itemTask) => {
            //拿到过滤出来数组的开始时间和结束时间
            return (
              (new Date(itemTask.startDate)),
              (new Date(itemTask.endDate))
            );
          });
        }
        let ids = evt.item.id; // 获取拖动元素的id
        if (
          evt.item.align === "cloudccTask" ||
          evt.item.align === "ServiceAppointment" ||
          parameter === "ffe20201207task11"
        ) {
          // else {
          if (evt.item.align === "cloudccTask") {
            this.obj = [{ id: ids, their_milestone: evt.to.id }];
          } else if (
            evt.item.align === "ServiceAppointment" ||
            parameter === "ffe20201207task11"
          ) {
            this.obj = [{ id: ids, milestone: evt.to.id }];
          }
          let params = {
            marketsea: "false",
            objectApi: evt.item.align,
            data: JSON.stringify(this.obj),
          };
          await CommonObjApi.save(params);
          this.getMilestoneMessage(this.getDatapPrameters);
        }
      }
    },
    // 里程碑下点击服务预约的名称进入详情页事件
    changeServiceName(ids) {
      this.$router.push({
        path: `/commonObjects/detail/${ids}/DETAIL`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.taskNameButton {
  width: 100%;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.divBtn {
  width: 98%;
  height: 50px;
  position: absolute;
  top: 57px;
  left: 0;
  z-index: 10;
  padding-top: 11px;
  .newSubtask{
    float: right;
    margin-right: 5px;
    margin-top: 35px;
  }
}
.tableData {
  width: 900px;
  height: 350px;
  margin-top: 10px;
  ::v-deep .el-table:not(.el-table–scrollable-x) .el-table__fixed-right {
    height: 100% !important;
  }
}
::v-deep .el-dialog {
  box-shadow: none;
  position: relative;
}
.newSubtask {
  position: relative;
  left: 818px;
}
.el-icon--right {
  margin-left: 0;
}
.percentage_task_box {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  .left_fixed_box {
    height: 100%;
    width: 20px;
    border: 1px solid red;
    background-color: #333;
    position: fixed;
    z-index: 2;
    left: 0px;
    bottom: 50px;
  }
  .page_is_empty_box {
    width: 100%;
    height: 100%;
    position: relative;
    .page_is_emptys {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -50px;
      margin-left: -50px;
      .noData {
        margin: 0 auto;
        text-align: center;
      }
    }
  }
  .percentage_list_box {
    display: flex;
    padding-top: 10px;
  }
  .loop_box {
    width: 320px;
    height: 100%;
    border: 2px solid #e3e2e2;
    border-radius: 3px;
    margin-right: 10px;
    overflow: hidden;
    .bai_fen_bi {
      width: 100%;
      height: 38px;
      position: relative;
      span {
        position: absolute;
        padding: 10px 0 0 10px;
        color: #080707;
        font-size: 15px;
      }
    }
    .you_xian_ji {
      width: 100%;
      height: 38px;
      position: relative;
      .bai_you {
        padding: 10px 0 0 10px;
      }
      .radius_wu {
        display: inline-block;
        border-radius: 50%;
        width: 11px;
        height: 11px;
        background: #8c8c8c;
      }
      .radius_di {
        display: inline-block;
        border-radius: 50%;
        width: 11px;
        height: 11px;
        background: #1c9aef;
      }
      .radius_zhong {
        display: inline-block;
        border-radius: 50%;
        width: 11px;
        height: 11px;
        background: #fb8c15;
      }
      .radius_gao {
        display: inline-block;
        border-radius: 50%;
        width: 11px;
        height: 11px;
        background: #e62412;
      }
      .Grade {
        padding-left: 5px;
        color: #080707;
        font-size: 15px;
      }
    }
    .li_cheng_bei {
      width: 100%;
      // height: 65px;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: #d8d8d8;
      padding: 10px;
      display: flex;
      .left_title {
        width: 84%;
        .li_cheng_bei_table {
          padding: 10px 0 0 10px;
          color: #0a0a0a;
          display: flex;
          width: 256px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .milestone_name {
            min-width: 20%;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 0px;
            }
          }
          .number_of_tasks {
            padding-left: 10px;
          }
        }
        .starting_and_ending_time {
          padding-left: 10px;
          padding-top: 10px;
          color: #ababab;
        }
      }
      .right_newly_build {
        width: 17%;
        .el-dropdown-link {
          cursor: pointer;
          color: #409eff;
          .pro-operation1 {
            width: 21px;
            height: 21px;
            margin: 18px;
          }
        }
        .el-icon-arrow-down {
          font-size: 12px;
        }
        .el-icon-more {
          font-size: 25px;
          padding: 5px 0px 0px 13px;
          cursor: pointer;
          color: #778899;
        }
      }
    }
    .draggable_box {
      height: calc(100% - 38px);
      overflow: auto;
      width: 329px;
      padding-top: 6px;
    }
  }
  .task_list_box {
    width: 328px;
    height: 100%;
    overflow: auto;
    .no_task_box {
      width: 100%;
      height: 100%;
      position: relative;
      .img_text {
        height: 100px;
        width: 100%;
        position: absolute;
        top: 30%;
        right: 2%;
        display: flex;
        flex-direction: column;
        span {
          margin-top: 20px;
          display: block;
          text-align: center;
          width: 100%;
        }
      }
    }
    .card_box {
      padding: 6px 6px 6px 8px;
      .first line {
        width: 300px;
        height: 167px;
        border: 1px solid red;
      }
    }
    .box-card {
      width: 300px;
      height: 167px;
      .title_box {
        width: 100%;
        height: 58px;
        .title_state {
          padding: 8px 0 0 1px;
          .title_name {
            font-size: 12px;
            color: #888888;
            padding-right: 5px;
          }
        }
        .task_name {
          font-size: 14px;
          color: #006dcc;
          padding: 4px 0 0 10px;
          width: 287px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            cursor: pointer;
          }
        }
        .left_title_state {
          width: 80%;
          .title_states {
            padding: 8px 0 0 1px;
            .title_name {
              font-size: 12px;
              color: #888888;
              padding-right: 5px;
            }
          }
          .task_nameS {
            font-size: 14px;
            color: #006dcc;
            padding: 4px 0 0 10px;
            width: 245px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              cursor: pointer;
            }
          }
        }
        .right_icon_box {
          width: 20%;
          height: 100%;
          position: relative;
          .change_icon {
            width: 30px;
            height: 30px;
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            i {
              cursor: pointer;
              font-size: 18px;
              padding-left: 10px;
            }
            i:hover {
              color: #006dcc;
            }
          }
          // 里程碑任务三个点样式
          ::v-deep .el-dropdown {
            padding: 4px 0px 0 12px;
            font-size: 21px;
            cursor: pointer;
          }
          ::v-deep .el-dropdown:hover {
            color: #006dcc;
          }
        }
      }
      .task_content_box {
        width: 100%;
        height: 58px;
        .img_content {
          padding: 8px 0 0 10px;
          display: flex;
          .img {
            width: 14px;
            height: 14px;
            margin: 4px 5px 0 0;
          }
          div {
            width: 255px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              // display: inline-block;
              font-size: 12px;
              color: #888888;
            }
          }
        }
        .project_name {
          padding: 3px 0 0 10px;
          color: #080707;
          width: 287px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .bottom_time_portrait {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1px 0px 0 10px;
        .leftBtn {
          width: 35%;
          display: flex;
          .edit {
            padding-right: 10px;
            font-size: 14px;
            color: #000;
          }
          .subtask {
            cursor: pointer;
            display: flex;
            .img {
              width: 15px;
              height: 20px;
            }
            div {
              font-size: 15px;
              color: #707070;
              padding-left: 6px;
            }
            el-dialog {
              // width: 90%;
            }
          }
        }
        .time_portrait {
          // white-space: nowrap;
          // padding: 2px 0px 0 135px;
          // span {
          //   font-size: 12px;
          //   color: #080707;
          //   padding-right: 5px;
          // }
          // img {
          //   width: 30px;
          //   height: 30px;
          //   border-radius: 50px;
          // }
          .end_date1 {
            padding-left: 3px;
          }
          .person_in_charge_box {
            display: flex;
            // width: 180px;
            .many_peoples {
              width: 142px;
              display: flex;
              .charges {
                padding: 4px 5px 0 0;
              }
              ::v-deep .el-button-group > .el-button:last-child {
                height: 28px !important;
              }
            }
            .charge {
              height: 21px;
              padding: 2px 3px 0 0;
            }
            // 任务百分比负责人下拉框样式
            .many_people {
              width: 106px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-top: 3px;
              cursor: pointer;
              ::v-deep .el-button-group > .el-button:not(:last-child) {
                font-size: 14px;
                height: 25px;
                width: 60px; //改大会影响其他语言的下拉按钮显示
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                border: none;
                padding: 0;
              }
              ::v-deep .el-button-group > .el-button + .el-button {
                height: 25px;
                border: none;
              }
              ::v-deep
                .el-dropdown
                .el-dropdown__caret-button.el-button--default::before {
                background: none;
              }
            }
            .many_peopless {
              width: 155px;
              display: flex;
              .charges {
                padding: 3px 2px 0 0;
              }
              // 任务负责人下拉框
              ::v-deep .el-button-group > .el-button:not(:last-child) {
                font-size: 14px;
                width: 60px; //改大会影响其他语言的下拉按钮显示
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border: none;
                padding: 0;
                height: 18px;
              }
              ::v-deep .el-button-group > .el-button:last-child {
                height: 28px;
                border: none;
              }
              ::v-deep
                .el-dropdown
                .el-dropdown__caret-button.el-button--default::before {
                background: none;
              }
            }
          }
        }
      }
    }
  }
}
::v-deep .el-card__body {
  padding: 0px;
}
// 分割线
::v-deep .el-divider--horizontal {
  width: 280px;
  margin: 0 auto;
}
// 状态圆角
// .el-tag.el-tag--info {
//   border-radius: 25px;
// }
// 文字中间画横线
.strikethrough {
  text-decoration: line-through;
  color: red;
}
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
